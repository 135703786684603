import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Hidden from '@material-ui/core/Hidden'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { graphql, GatsbyLinkProps, Link as RouterLink, useStaticQuery } from 'gatsby'
import { path } from 'ramda';
import { format } from 'date-fns';

import Layout from '../../components/layout'
import SEO from '../../components/seo'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbarTitle: {
    flex: 1
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto'
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0
  },
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: props => `url(${props.mainPostBackgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)'
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0
    }
  },
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: 'flex',
    maxHeight: '300px',
    minHeight: '300px',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    // width: 160
    width: '100%',
    maxHeight: '220px'
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0)
  },
  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200]
  },
  sidebarSection: {
    marginTop: theme.spacing(3)
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0)
  },
  moreReadLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none '
    }
  },
  moreReadText: {
    position: 'absolute',
    bottom: '20px'
  }
}))

const sections = [
  'Technology',
  'Design',
  'Culture',
  'Business',
  'Politics',
  'Opinion',
  'Science',
  'Health',
  'Style',
  'Travel'
]

const archives = [
  'March 2020',
  'February 2020',
  'January 2020',
  'December 2019',
  'November 2019',
  'October 2019',
  'September 2019',
  'August 2019',
  'July 2019',
  'June 2019',
  'May 2019',
  'April 2019'
]

const social = ['GitHub', 'Twitter', 'Facebook']

const LinkComponent = React.forwardRef<
  HTMLAnchorElement,
  Omit<GatsbyLinkProps<{}>, 'ref'>
>((props, ref) => (
  // @ts-ignore
  <RouterLink innerRef={ref} {...props} />
))

export default (...args) => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulNewsPost(
        sort: { fields: [publishDate], order: DESC }
        filter: { node_locale: { eq: "en" } }
      ) {
        edges {
          node {
            title
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            heroImage {
              localFile {
                publicURL
              }
            }
            body {
              json
            }
            publishDate
            slug
          }
        }
      }
    } 
  `);

  const getData = (initial) => {
    const title = initial.title;
    const pusblishDate = format(initial.publishDate, 'MMMM D, YYYY');
    const description = initial.description.childMarkdownRemark.rawMarkdownBody.split(".")[0];
    const slug = initial.slug;
    const publicURL = initial.heroImage.localFile.publicURL;
    const final:blog = {
      "title": title,
      "publishDate": pusblishDate,
      "description": description,
      "slug": slug,
      "publicURL": publicURL,
    }
    return final;
  }

  const mainPost = path(['allContentfulNewsPost', 'edges', 0, 'node'], data)
  const secondPost = path(['allContentfulNewsPost', 'edges', 1, 'node'], data)
  const thirdPost = path(['allContentfulNewsPost', 'edges', 2, 'node'], data)

  interface blog {
    title: string,
    publishDate: string,
    description: string,
    slug: string,
    publicURL: string,
  }

  interface blogs {
    blogList: blog[];
  }

  interface Older {
    status: boolean;
  }

  const initialOlder: Older = {
    status: true
  }

  const [olderStatus, setOlderStatus] = React.useState<Older>(initialOlder);

  const initialBlogs: blogs = {
    blogList: [getData(secondPost), getData(thirdPost)]
  }

  const [blogs, setBlogs] = React.useState<blogs>(initialBlogs);
  console.log("blogs:", blogs);

  const displayOlderBlogs = () => {
    const currentBlogLength = blogs.blogList.length;
    const firstPost = path(['allContentfulNewsPost', 'edges', currentBlogLength+1, 'node'], data);
    if (firstPost !== undefined) {
      setBlogs((blogs) => ({
        blogList: blogs.blogList.concat(getData(firstPost))
      }))
      const secondPost = path(['allContentfulNewsPost', 'edges', currentBlogLength+2, 'node'], data);
      if (secondPost !== undefined) {
        setBlogs((blogs) => ({
          blogList: blogs.blogList.concat(getData(secondPost))
        }))
      } else {
        setOlderStatus(() => ({
          status: false 
        }))
      }
    } else {
      setOlderStatus(() => ({
        status: false 
      }))
    }
  }

  const classes = useStyles({
    mainPostBackgroundImage: mainPost.heroImage.localFile.publicURL
  });

  return (
    <Layout>
      <SEO title="News" />
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="md">
          <Box my={3}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link component={LinkComponent} color="inherit" to="/en">
                Home
              </Link>
              <Typography color="textPrimary">News</Typography>
            </Breadcrumbs>
          </Box>

          <main>

            <Paper className={classes.mainFeaturedPost}>

              {
                <img
                  style={{ display: 'none' }}
                  src={mainPost.heroImage.localFile.publicURL}
                  alt="background"
                />
              }

              <div className={classes.overlay} />
              <Grid container>
                <Grid item md={6}>
                  <div className={classes.mainFeaturedPostContent}>
                    <Typography
                      component="h1"
                      variant="h3"
                      color="inherit"
                      gutterBottom
                    >
                      {mainPost.title}
                    </Typography>
                    <Typography variant="h5" color="inherit" paragraph>
                      {mainPost.description.childMarkdownRemark.rawMarkdownBody}
                    </Typography>
                    <Link variant="subtitle1" href={`/en/news/${mainPost.slug}`}>
                      Continue reading…
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Paper>

            <Grid container spacing={4}>
              {blogs.blogList.map((post) => (
                <Grid item key={post.title} xs={12} md={6}>
                  {/* <CardActionArea component="a" href=""> */}
                  <CardActionArea>
                    <img
                      className={classes.cardMedia}
                      src={post.publicURL}
                      alt="background"
                    />
                    <Card className={classes.card}>                    
                      <div className={classes.cardDetails}>
                        <CardContent>
                          <Typography component="h2" variant="h5">
                            {post.title}
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            {post.publishDate}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                            {post.description}
                          </Typography>
                          <Link
                           href={`/en/news/${post.slug}`}
                           className={classes.moreReadLink}
                          >
                            <Typography variant="subtitle1" color="primary" className={classes.moreReadText}>
                              Continue reading...
                            </Typography>
                          </Link>
                        </CardContent>
                      </div>
                      {/* <Hidden xsDown> */}
                        {/* <CardMedia
                          className={classes.cardMedia}
                          image="https://source.unsplash.com/random"
                          title="Image title"
                        /> */}
                      {/* </Hidden> */}
                    </Card>
                  </CardActionArea>
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={5} className={classes.mainGrid}>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {olderStatus.status ? (
                    <Button onClick={displayOlderBlogs}>
                      Older  
                    </Button>   
                  ) : (
                    "No more"
                  )}
                                
                </Typography>
                <Divider />
                {/* {posts.map(post => (
              <Markdown className={classes.markdown} key={post.substring(0, 40)}>
                {post}
              </Markdown>
            ))} */}
              </Grid>

            </Grid>
          </main>
        </Container>
      </React.Fragment>
      >
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulNewsPost(limit: 6) {
      edges {
        node {
          slug
        }
      }
    }
  }
`
